import React, { useEffect, useRef, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import Header from "../../../layouts/header";
import Sidebar from "../../../layouts/sidebar";
import { connect } from "react-redux";
import { getListingMedia, getNcndaTemplate, updateNcndaTemplate } from "../../../store/listingsMedia";
import { toast } from "react-toastify";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import Skeleton from "react-loading-skeleton";
import NcndaEditModal from "./modals/ncndaEditModal";
import AlertError from "../../../common/alerts/alertError";
import { getProfile } from "../../../utils.js/localStorageServices";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const NcndaEdit = (props) => {
  const ref = useRef(null);
  const profile = getProfile();
  const history = useHistory();
  const logoUrl = "https://s3.amazonaws.com/media-dev.salvex.com/emails/salvex2x.png";
  const access = profile?.accessLevels?.document_template_access ? true : false;
  const data = props?.getListingMedia?.ncnda?.templates || [];
  const [showMenu, setShowMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [menuList, setMenuList] = useState("");
  const [tempData, setTempData] = useState("");
  const [id, setId] = useState("");
  const [editText, setEditText] = useState("");
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    if (!access) {
      history.push("/404");
    }
  }, [profile]);

  const toggleEdit = () => {
    setShowEdit(!showEdit);
  };

  useEffect(() => {
    let value = "";
    if (data?.length > 0) {
      data.map((val) => {
        if (val?.sr_no < 7) {
          value = value + val.content;
        }
        if (val?.sub_type === "body2b") {
          setId(val?.id);
          setEditText(val?.content);
        }
      });
      setTempData(value);
    }
  }, [data]);

  useEffect(() => {
    ref.current.continuousStart();
    props?.getNcndaTemplate({ type: "ncnda" }, (res) => {
      if (res.status === 200) {
        ref.current.complete();
      } else {
        ref.current.complete();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  }, []);

  const handleEdit = () => {
    setLoading(true);
    ref.current.continuousStart();
    const payload = {
      id: id,
      content: editText?.replace(/:<ol><li>/g, ":<ol type='a'><li>"),
    };
    props?.updateNcndaTemplate(payload, (res) => {
      if (res.status === 200) {
        props?.getNcndaTemplate({ type: "ncnda" }, (res) => {});
        ref.current.complete();
        setLoading(false);
        toggleEdit();
        toast(<AlertSuccess message="Information Saved" />);
      } else {
        ref.current.complete();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Header />
      <div class="dashboard-insider position-relative d-lg-flex w-100 " style={{ marginTop: "80px" }}>
        <Sidebar
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          menuList={menuList}
          setMenuList={setMenuList}
          page="mfrAndModel"
          pageCat="ncnda"
        />
        <div className={showMenu ? ` main-menu-expanded ` : "main-menu-compressed"}>
          <div class="filter-container d-flex align-items-center sticky-top">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 d-flex align-items-center flex-wrap justify-content-between">
                  <div class="filter-title fw-medium text-capitalize">NCNDA</div>
                  <div class="filter-widget-right d-flex align-items-center flex-wrap">
                    <div class="fw-item">
                      {props.loading ? (
                        <Skeleton width="50px" height="30px" />
                      ) : (
                        <div className="d-flex ">
                          <div>
                            <button className="btn btn-sm btn-primary" onClick={toggleEdit}>
                              Edit
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-flex justify-content-center align-items-center w-100"
            dangerouslySetInnerHTML={{
              __html: tempData?.replace(/#\{logo\}/g, logoUrl),
            }}
          />
        </div>
      </div>
      <NcndaEditModal
        show={showEdit}
        onHide={toggleEdit}
        editText={editText}
        loading={loading}
        setEditText={setEditText}
        handleSubmit={handleEdit}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getNcndaTemplate: (params, callback) => dispatch(getNcndaTemplate(params, callback)),
  updateNcndaTemplate: (data, callback) => dispatch(updateNcndaTemplate(data, callback)),
});

const mapStateToProps = (state) => ({
  getListingMedia: getListingMedia(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(NcndaEdit));
