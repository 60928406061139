import React, { useEffect, useReducer, useRef, useState } from "react";
import Header from "../../layouts/header";
import { connect } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import { toast } from "react-toastify";
import AlertError from "../../common/alerts/alertError";
import { getAviationDetails, getReminderParts } from "../../store/aviation";
import ReminderPartsTable from "../../components/aviation/reminderParts/reminderPartsTable";
import ReminderPartsFilter from "../../components/aviation/reminderParts/reminderPartsFilter";
import ViewDetails from "../../components/listingDetail/aviationParts/viewDetails";

const initialState = {
  loading: false,
  reminderParts: [],
  filterValues: { keyword: "", order: "asc", sort: "updatedAt" },
  page: 1,
  totalPages: 1,
  totalCount: 1,
  filteredCount: 1,
  isFiltered: false,
  showFilter: false,
  selectedItem: null,
  showDetails: false,
};

// Reducer function
const reminderReducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "SET_REMINDER_DATA":
      return {
        ...state,
        reminderParts: action.payload.data || [],
        loading: false,
        totalPage: action.payload?.pagination?.totalPage || 1,
        totalCount: action.payload?.pagination?.totalCount || 1,
        filteredCount: action.payload?.pagination?.filteredCount || 1,
        isFiltered: false,
      };
    case "NEXT_PAGE":
      return {
        ...state,
        loading: true,
        page: state.page + 1,
      };
    case "PREVIOUS_PAGE":
      return {
        ...state,
        loading: true,
        page: state.page > 1 ? state.page - 1 : 1,
      };
    case "SET_KEYWORD":
      return {
        ...state,
        filterValues: { ...state.filterValues, keyword: action.payload },
        isFiltered: action.payload?.length > 0 ? false : state.showFilter ? false : true,
      };
    case "SET_SORTING_DATA":
      return {
        ...state,
        filterValues: { ...state.filterValues, order: action.payload?.order, sort: action.payload?.sort },
        isFiltered: true,
      };
    case "TOGGLE_FILTER":
      return {
        ...state,
        showFilter: action.payload,
      };
    case "TOGGLE_VIEW_DETAILS":
      return {
        ...state,
        showDetails: action.payload.showDetails,
        selectedItem: action.payload?.item ? action.payload?.item : null,
      };
    default:
      return state;
  }
};

const ReminderParts = (props) => {
  const ref = useRef(null);
  const [state, dispatch] = useReducer(reminderReducer, initialState);
  const [response, setResponse] = useState({
    res: false,
    keyword: "",
  });
  const getReminders = () => {
    dispatch({ type: "LOADING", payload: true });
    const params = {
      ...state.filterValues,
      page: 1,
    };

    props?.getReminderParts(params, (res) => {
      if (res && res.status === 200) {
        dispatch({ type: "SET_REMINDER_DATA", payload: res?.data });
      } else {
        dispatch({ type: "LOADING", payload: false });
        toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
      }
    });
  };
  useEffect(() => {
    getReminders();
  }, [state.page]);

  useEffect(() => {
    if (state.isFiltered) {
      getReminders();
    }
  }, [state.isFiltered]);

  const handleFilter = () => {
    dispatch({ type: "LOADING", payload: true });
    const params = {
      page: 1,
      ...state.filterValues,
    };

    props?.getReminderParts(params, (res) => {
      if (res && res.status === 200) {
        setResponse({
          res: true,
          keyword: state?.filterValues?.keyword,
        });
        dispatch({ type: "SET_REMINDER_DATA", payload: res?.data });
        // dispatch({ type: "SET_KEYWORD", payload: response?.keyword });
        dispatch({ type: "TOGGLE_FILTER", payload: false });
      } else {
        dispatch({ type: "LOADING", payload: false });
        toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
      }
    });
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Header />
      <ReminderPartsTable state={state} dispatch={dispatch} response={response} setResponse={setResponse} />
      <ReminderPartsFilter state={state} dispatch={dispatch} handleFilter={handleFilter} />

      <ViewDetails
        show={state.showDetails}
        onHide={() => dispatch({ type: "TOGGLE_VIEW_DETAILS", payload: { showDetails: !state.showDetails } })}
        selectedValue={state?.selectedItem?.aviationPart}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getReminderParts: (params, callback) => dispatch(getReminderParts(params, callback)),
});

const mapStateToProps = (state) => ({
  getAviation: getAviationDetails(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ReminderParts));
