import React, { useState } from "react";
import close from "../../../include/images/close-icon.svg";

import emptyFolder from "../../../include/images/folder.svg";
import moment from "moment/moment";
import Skeleton from "react-loading-skeleton";
import Sidebar from "../../../layouts/sidebar";
const ReminderPartsTable = ({ state, dispatch, response, setResponse, ...props }) => {
  //For SideNav
  const [showMenu, setShowMenu] = useState(false);
  const [menuList, setMenuList] = useState("");

  return (
    <>
      <div class="dashboard-insider position-relative d-lg-flex w-100 ">
        <Sidebar
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          menuList={menuList}
          setMenuList={setMenuList}
          page="aviation"
          pageCat="reminder-parts"
        />
        <div className={showMenu ? ` main-menu-expanded ` : "main-menu-compressed"}>
          <div class="filter-container d-flex align-items-center sticky-top">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 d-flex align-items-center flex-wrap justify-content-between">
                  <div class="filter-title fw-medium text-capitalize">Reminder Parts</div>
                  <div class="filter-widget-right d-flex align-items-center flex-wrap">
                    <div class="fw-item">
                      {props.loading ? (
                        <Skeleton width="50px" height="30px" />
                      ) : (
                        <div
                          class="filter-row d-flex align-items-center"
                          onClick={() => {
                            dispatch({ type: "TOGGLE_FILTER", payload: true });
                            dispatch({ type: "SET_KEYWORD", payload: response?.keyword });
                          }}
                        >
                          <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M2.39767 16.5C2.07751 16.5 1.81796 16.2357 1.81796 15.9097V1.09032C1.81796 0.764295 2.07751 0.5 2.39767 0.5C2.71784 0.5 2.97738 0.764295 2.97738 1.09032V15.9097C2.97738 16.2357 2.71784 16.5 2.39767 16.5Z"
                              fill="currentColor"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M13.0226 15.9097C13.0226 16.2357 13.2822 16.5 13.6023 16.5C13.9225 16.5 14.182 16.2357 14.182 15.9097V1.09032C14.182 0.764295 13.9225 0.5 13.6023 0.5C13.2822 0.5 13.0226 0.764295 13.0226 1.09032V15.9097ZM0.57971 13.18H4.21565C4.53582 13.18 4.79536 12.9158 4.79536 12.5897C4.79536 12.2637 4.53582 11.9994 4.21565 11.9994H0.57971C0.259545 11.9994 0 12.2637 0 12.5897C0 12.9158 0.259545 13.18 0.57971 13.18Z"
                              fill="currentColor"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.42029 15.9097C7.42029 16.2357 7.67983 16.5 8 16.5C8.32016 16.5 8.57971 16.2357 8.57971 15.9097V1.09032C8.57971 0.764295 8.32016 0.5 8 0.5C7.67983 0.5 7.42029 0.764295 7.42029 1.09032V15.9097ZM11.7843 13.18H15.4203C15.7405 13.18 16 12.9158 16 12.5897C16 12.2637 15.7405 11.9994 15.4203 11.9994H11.7843C11.4642 11.9994 11.2046 12.2637 11.2046 12.5897C11.2046 12.9158 11.4642 13.18 11.7843 13.18Z"
                              fill="currentColor"
                            />
                            <path
                              d="M9.81798 5.00058H6.18204C5.86187 5.00058 5.60233 4.73629 5.60233 4.41027C5.60233 4.08424 5.86187 3.81995 6.18204 3.81995H9.81798C10.1381 3.81995 10.3977 4.08424 10.3977 4.41027C10.3977 4.73629 10.1381 5.00058 9.81798 5.00058Z"
                              fill="currentColor"
                            />
                          </svg>
                          <span>Filters</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-container">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="tb-filter-row d-flex align-items-start justify-content-between">
                    <div class="filter-tags d-flex align-items-start">
                      {!state.loading && response?.res && (
                        <>
                          <div class="filter-tag-title">Filters Applied :</div>

                          <div class="tags">
                            {response?.res && (
                              <span class="badge">
                                Keyword : <span>{response?.keyword && response?.keyword}</span>
                                <img
                                  class="tag-close-icon"
                                  src={close}
                                  alt=""
                                  onClick={() => {
                                    dispatch({ type: "SET_KEYWORD", data: "" });
                                    setResponse({
                                      res: false,
                                      keyword: "",
                                    });
                                  }}
                                />
                              </span>
                            )}
                          </div>
                        </>
                      )}{" "}
                      {state.loading && (
                        <div class="filter-tags d-flex align-items-start">
                          <div class="filter-tag-title">
                            <div class="tags d-flex align-items-start">
                              <span class="badge">
                                <Skeleton />
                              </span>
                              <span class="badge">
                                <Skeleton />
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div class="tb-filter-right d-flex align-items-center justify-content-end">
                      <div class="showing-result-text">
                        {!state.loading && state.totalCount < 20 ? (
                          `Showing ${state.totalCount} of ${state.totalCount} total results`
                        ) : !state.loading && state.page == state.totalPages ? (
                          `Showing ${state.totalCount} of ${state.totalCount} total results`
                        ) : !state.loading && state?.page && state?.filteredCount ? (
                          `Showing ${20 * (state.page - 1) + state.filteredCount} of ${state.totalCount} total results`
                        ) : (
                          <Skeleton width="300px" height="25px" />
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="selected-table-row d-flex justify-content-between"></div>
                  <div
                    class={`table-responsive position-relative ${
                      state.reminderParts && state.reminderParts.length > 0 ? "min-user-table-height" : ""
                    }`}
                  >
                    <div class="table-selected-count d-none align-items-center justify-content-between">
                      <div class="tsc-check-label d-flex align-items-center">
                        <div class="tsc-checkbox">
                          <div class="form-check tick-checkbox">
                            <input class="form-check-input" type="checkbox" value="" id="checkAll" />
                            <label class="form-check-label" for="checkAll"></label>
                          </div>
                        </div>
                        <div class="link-label fw-medium">2 Selected</div>
                        <div class="link-clear fw-medium">Clear all</div>
                      </div>
                    </div>
                    <table class="table align-middle table-collapse-table">
                      <thead>
                        <tr>
                          {/* <th style={{ width: "auto" }}></th> */}

                          <th style={{ width: "20%" }}>
                            <a href="javascript:void(0)" class="sort-by">
                              Part Number
                            </a>
                          </th>
                          <th style={{ width: "20%" }}>
                            <a href="javascript:void(0)" className={`sort-by`}>
                              Buyer Info
                            </a>
                          </th>
                          <th style={{ width: "20%" }}>
                            <a href="javascript:void(0)" className={`sort-by`}>
                              Reminder By
                            </a>
                          </th>
                          <th style={{ width: "10%" }}>
                            <a
                              href="javascript:void(0)"
                              className={`sort-by ${state?.filterValues?.sort === "createdAt" ? "active-column" : ""}`}
                            >
                              Date
                              <div
                                class={`ascending-icon cursor-pointer ${
                                  state?.filterValues?.sort === "createdAt" && state?.filterValues?.order === "asc"
                                    ? "sort-active"
                                    : ""
                                }`}
                                onClick={() =>
                                  dispatch({ type: "SET_SORTING_DATA", payload: { sort: "createdAt", order: "asc" } })
                                }
                              ></div>
                              <div
                                class={`descending-icon cursor-pointer ${
                                  state?.filterValues?.sort === "createdAt" && state?.filterValues?.order === "desc"
                                    ? "sort-active1"
                                    : ""
                                }`}
                                onClick={() =>
                                  dispatch({ type: "SET_SORTING_DATA", payload: { sort: "createdAt", order: "desc" } })
                                }
                              ></div>
                            </a>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {state.loading
                          ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4].map((item) => (
                              <tr>
                                <td colspan="8">
                                  <table class="table2">
                                    <tr>
                                      <td style={{ width: "20%" }}>
                                        <Skeleton />
                                      </td>
                                      <td style={{ width: "10%" }}>
                                        <Skeleton />
                                      </td>
                                      <td style={{ width: "20%" }}>
                                        <Skeleton />
                                      </td>
                                      <td style={{ width: "20%" }}>
                                        <Skeleton />
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            ))
                          : state.reminderParts &&
                            state.reminderParts.length > 0 &&
                            state.reminderParts.map((item) => {
                              const date = moment(item?.createdAt).format("MMM-DD-YYYY");
                              const name = item.name;
                              const email = item.email;
                              const userid = item.userId;
                              return (
                                <tr>
                                  <td colspan="8">
                                    <table class="table2">
                                      <tr>
                                        <td style={{ width: "20%" }}>
                                          <button
                                            className="green-btn-text"
                                            onClick={() => {
                                              dispatch({
                                                type: "TOGGLE_VIEW_DETAILS",
                                                payload: {
                                                  item: item,
                                                  showDetails: true,
                                                },
                                              });
                                            }}
                                          >
                                            {item?.partNumber ? item?.partNumber : "N/A"}
                                          </button>
                                        </td>
                                        <td style={{ width: "20%" }}>
                                          {name || email ? (
                                            <>
                                              <span
                                                className="green-btn-text cursor-pointer"
                                                onClick={() => {
                                                  if (name) {
                                                    if (process.env.REACT_APP_MODE == "development") {
                                                      window.open(
                                                        `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${userid}`,
                                                      );
                                                    } else {
                                                      window.open(
                                                        `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${userid}`,
                                                      );
                                                    }
                                                  }
                                                }}
                                              >
                                                {name}
                                              </span>
                                              <br />
                                              <a className="black-color-text text-wrapper" href={`mailto:${email}`}>
                                                {email}
                                              </a>
                                            </>
                                          ) : (
                                            "N/A"
                                          )}
                                        </td>
                                        <td style={{ width: "20%" }}>
                                          {item?.reminderByName || item?.reminderByEmail ? (
                                            <>
                                              <span
                                                className="green-btn-text cursor-pointer"
                                                onClick={() => {
                                                  if (item?.reminderByName) {
                                                    if (process.env.REACT_APP_MODE == "development") {
                                                      window.open(
                                                        `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${item?.reminderByUserId}`,
                                                      );
                                                    } else {
                                                      window.open(
                                                        `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${item?.reminderByUserId}`,
                                                      );
                                                    }
                                                  }
                                                }}
                                              >
                                                {item?.reminderByName}
                                              </span>
                                              <br />
                                              <a
                                                className="black-color-text text-wrapper"
                                                href={`mailto:${item?.reminderByEmail}`}
                                              >
                                                {item?.reminderByEmail}
                                              </a>
                                            </>
                                          ) : (
                                            "N/A"
                                          )}
                                        </td>
                                        <td style={{ width: "10%" }}>
                                          <span>{date}</span>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              );
                            })}
                      </tbody>
                    </table>
                  </div>
                  {!state.loading && state.reminderParts && state.reminderParts.length === 0 && (
                    <>
                      <div class="table-empty">
                        <div class="table-empty-inner">
                          <img src={emptyFolder} alt="" />
                          <p>No Items Found</p>
                        </div>
                      </div>
                    </>
                  )}

                  <div class="table-pagination">
                    <ul class="pagination  justify-content-end">
                      <li class="page-item">
                        <a
                          class={`page-link ${state.page === 1 ? "disabled" : ""}`}
                          href="#"
                          aria-label="Previous"
                          //   onClick={() => {
                          //     if (state.page !== 1) {
                          //       props.setPage(props.page - 1);
                          //     }
                          //   }}
                        >
                          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </a>
                      </li>
                      <li class="page-item active">
                        <a class="page-link" href="javascript:void(0)">
                          {state.page}
                        </a>
                      </li>
                      {state.totalPages > 1 && state.page + 1 <= state.totalPages && (
                        <li class="page-item">
                          <a class="page-link" href="#" onClick={() => state.setPage(state.page + 1)}>
                            {state.page + 1}
                          </a>
                        </li>
                      )}
                      <li class="page-item">
                        <a
                          class={`page-link ${state.page + 1 >= state.totalPages ? "disabled" : ""}`}
                          href="#"
                          aria-label="Next"
                          onClick={() => state.setPage(state.page + 1)}
                        >
                          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(ReminderPartsTable);
