import React, { useEffect, useState } from "react";
import NextButton from "../../../common/form/nextButton";
import {
  addMessage,
  buyerInterest,
  callEmailClicker,
  changeUnsubscribe,
  deleteMessage,
  emailClickersDetail,
  getPushEmailClickers,
  getRecipient,
  getUserDetail,
  quickEdit,
} from "../../../store/emailClickers";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import { connect } from "react-redux";
import greenEmail from "../../../include/images/green-mail.svg";
import notVerified from "../../../include/images/mail-not-verified.svg";
import invalid from "../../../include/images/mail-invalid.svg";
import spamEmail from "../../../include/images/mail-spammed-blocked.svg";
import purpleEmail from "../../../include/images/mail-purple.svg";
import DateFormat from "../../../common/DateFormat/dateFormat";
import Skeleton from "react-loading-skeleton";
import { ref } from "joi";
import extLink from "../../../include/images/external-link.svg";
import MoreModal from "../../../layouts/modals/pushEmailClickers/moreModal";
import UserContactInformationModal from "../../../layouts/modals/pushEmailClickers/userContactInformationModal";
import { getCountry, getState } from "../../../store/users";
import msgSqr from "../../../include/images/message-square.svg";
import { getOtherBuyerListing } from "../../../store/otherListings";
import { getListings } from "../../../store/listings";
import Select from "react-select";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
const EndUsersOfCategory = (props) => {
  const [showTable, setShowTable] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [fetchUser, setFetchUser] = useState(false);
  const [listingData, setListingData] = useState([]);
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [recipientList, setRecipientList] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [reg, setReg] = useState(1);
  const [cat, setCat] = useState(1);
  const [quickEditLoading, setQuickEditLoading] = useState(false);
  const [contactInformation, setContactInformation] = useState({
    customer_name: "",
    customer_city: "",
    customer_email: "",
    customer_phone: "",
    customer_cell: "",
    linkedin_url: "",
    state_id: 0,
    country_id: 0,
  });
  const [sort, setSort] = useState("createdAt");
  const [order, setOrder] = useState("desc");

  const count = props?.count;
  const productDetail = props?.getListings?.detail?.detail;

  useEffect(() => {
    if (showContactModal) {
      props.getCountry((res) => {
        if (res.status === 200) {
          setCountryOptions(res.data);
        }
      });
      props.getState((res) => {
        if (res.status === 200) {
          setStateOptions(res.data);
        }
      });
    }
  }, [showContactModal]);

  const userDetailData = props?.emailClickersDetail?.user?.data;

  const toggleMoreModal = () => {
    setShowMoreModal(!showMoreModal);
  };

  //Toggle User Contact Information Modal
  const toggleContactInformationModal = () => {
    setShowMoreModal(!showMoreModal);
    setShowContactModal(!showContactModal);
  };

  const Count = listingData?.paginationObj?.totalRecords;

  const fetchEmailClickers = () => {
    const payload = {
      aType: 12,
      owner_category_id: props?.categoryId,
      sort_by: sort,
      sort_by_order: order,
      state_id: productDetail?.state_id ? productDetail?.state_id : 0,
      country_id: productDetail?.country_id ? productDetail?.country_id : 0,
      reg: 0,
      cat: 0,
      mfr: productDetail?.mfr ? productDetail?.mfr : 0,
      page: page,
      limit: 10,
    };
    setLoading(true);
    props.refStart();
    props.getOtherBuyerListing(props.auctionId, payload, (res) => {
      if (res.status === 200) {
        setListingData(res?.data?.data);
        setLoading(false);
        props.refStop();
        setShowTable(true);
      } else {
        setLoading(false);
        props.refStop();
        toast(<AlertError message={res?.data?.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const handleBuyingInterest = (userId) => {
    const payload = {
      aType: 12,
      owner_category_id: props?.categoryId,
      sort_by: sort,
      sort_by_order: order,
      state_id: productDetail?.state_id ? productDetail?.state_id : 0,
      country_id: productDetail?.country_id ? productDetail?.country_id : 0,
      reg: 0,
      cat: 0,
      mfr: productDetail?.mfr ? productDetail?.mfr : 0,
      page: page,
      limit: 10,
    };
    props.refStart();
    props.buyerInterest(props.auctionId, userId, (res) => {
      if (res.status === 200) {
        props.getOtherBuyerListing(props.auctionId, payload, (res) => {
          if (res.status === 200) {
            props.refStop();
            setListingData(res?.data?.data);
          } else {
            props.refStop();
            toast(<AlertError message="Something Went Wrong" />);
          }
        });
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  useEffect(() => {
    if (showTable) {
      fetchEmailClickers();
    }
  }, [page]);

  useEffect(() => {
    if (showTable) {
      const payload = {
        aType: 12,
        owner_category_id: props?.categoryId,
        sort_by: sort,
        sort_by_order: order,
        state_id: productDetail?.state_id ? productDetail?.state_id : 0,
        country_id: productDetail?.country_id ? productDetail?.country_id : 0,
        reg: reg,
        cat: cat,
        mfr: productDetail?.mfr ? productDetail?.mfr : 0,
        page: 1,
        limit: 10,
      };
      setLoading(true);
      props.refStart();
      props.getOtherBuyerListing(props.auctionId, payload, (res) => {
        if (res.status === 200) {
          setPage(1);
          setListingData(res?.data?.data);
          setLoading(false);
          props.refStop();
          setShowTable(true);
        } else {
          setLoading(false);
          props.refStop();
          toast(<AlertError message={res?.data?.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  }, [cat, reg, sort, order]);

  const handleCallEmailClicker = (userId) => {
    const payload = {
      aType: 12,
      owner_category_id: props?.categoryId,
      sort_by: sort,
      sort_by_order: order,
      state_id: productDetail?.state_id ? productDetail?.state_id : 0,
      country_id: productDetail?.country_id ? productDetail?.country_id : 0,
      reg: 0,
      cat: 0,
      mfr: productDetail?.mfr ? productDetail?.mfr : 0,
      page: page,
      limit: 10,
    };
    props.refStart();
    props.callEmailClicker(props.auctionId, userId, (res) => {
      if (res.status === 200) {
        props.getOtherBuyerListing(props.auctionId, payload, (res) => {
          if (res.status === 200) {
            props.refStop();
            setListingData(res?.data?.data);
          } else {
            props.refStop();
            toast(<AlertError message="Something Went Wrong" />);
          }
        });
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const fetchUserDetail = (userId) => {
    props.refStart();
    props.getUserDetail(userId, (res) => {
      if (res.status === 200) {
        // setUserDetailData(res.data.data);
        props.getRecipient((res) => {
          if (res.status === 200) {
            setRecipientList(res.data.data);
            props.refStop();
            toggleMoreModal();
          } else {
            toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
            props.refStop();
            toggleMoreModal();
          }
        });
      } else {
        toast(<AlertError message={res.data.message ? res.data.messge : "Something Went Wrong"} />);
      }
    });
  };

  //Quick Edit
  const quickEditHandle = (userId) => {
    props.refStart();
    setQuickEditLoading(true);
    props.quickEdit(userId, contactInformation, (res) => {
      if (res.status === 200) {
        props.getUserDetail(userId, (res) => {
          if (res.status === 200) {
            props.refStop();
            setQuickEditLoading(false);
            toggleContactInformationModal();
          } else {
            props.refStop();
            setQuickEditLoading(false);
            toggleContactInformationModal();
          }
        });
      } else {
        props.refStop();
        setQuickEditLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  return (
    <div class="details-page-widget-block" id={props.id}>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center">
            <div
              class={
                !showTable && !props.loadingMain
                  ? "page-widget-label text-capitalize cursor-pointer"
                  : "page-widget-label text-capitalize cursor-pointer"
              }
              onClick={() => {
                if (!showTable && !props.loadingMain) {
                  fetchEmailClickers();
                } else {
                  setShowTable(false);
                  setReg(1);
                  setCat(1);
                }
              }}
            >
              End Users Of {props?.categoryName ? props?.categoryName : ""}
              {!props.loadingMain && count > 0
                ? `(${count})`
                : props.loadingMain
                ? ""
                : `(${count ? count : "0"})`}{" "}
            </div>
          </div>
          <li
            className="badge-item cursor-pointer"
            onClick={() => {
              if (!showTable && !props.loadingMain) {
                fetchEmailClickers();
              } else {
                setShowTable(false);
              }
            }}
            style={{ marginLeft: "10px" }}
          >
            <div className="badge-box" style={{ background: "#DADEE0" }}>
              {showTable ? "Hide" : "Show"}
            </div>
          </li>
        </div>
      </div>
      {props.loadingMain ? (
        <Skeleton height="239px" />
      ) : (
        <div class={listingData?.list?.length > 0 && "page-widget-body mt-3"}>
          {showTable && (
            <>
              <div className="d-flex mt-2">
                <div className="d-flex me-3">
                  <span className="fw-medium mt-2">Region: </span>
                  <div className="ms-2" style={{ width: "200px" }}>
                    <Select
                      styles={customStyles}
                      value={[
                        { label: "Local", value: "1" },
                        { label: "Global", value: "2" },
                      ]?.find((val) => val?.value == reg)}
                      options={[
                        { label: "Local", value: "1" },
                        { label: "Global", value: "2" },
                      ]}
                      onChange={(e) => setReg(e.value)}
                    />
                  </div>
                </div>
                <div className="d-flex me-3">
                  <span className="fw-medium mt-2">Category Match: </span>
                  <div className="ms-2" style={{ width: "250px" }}>
                    <Select
                      styles={customStyles}
                      value={[
                        { label: "Sub Category", value: "1" },
                        { label: "Main Category", value: "2" },
                      ]?.find((val) => val?.value == cat)}
                      options={[
                        { label: "Sub Category", value: "1" },
                        { label: "Main Category", value: "2" },
                      ]}
                      onChange={(e) => setCat(e.value)}
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                {listingData?.list?.length > 0 && (
                  <div className="d-flex text-right">
                    Page {page} of {Count % 10 === 0 ? Math.floor(Count / 10) : Math.floor(Count / 10) + 1}
                    {`(${Count} records)`}
                  </div>
                )}
                <div class="col-md-12">
                  <div class={listingData?.list?.length > 0 && "table-responsive position-relative mt-3"}>
                    {listingData?.list?.length > 0 && (
                      <table class="table table-bordered custom-table mb-0">
                        <thead>
                          <tr>
                            <th style={{ width: "200px", minWidth: "195px" }}>
                              <a href="javascript:void(0)" class="sort-by" onClick={() => setSort("customer")}>
                                Customer
                                <div
                                  class={
                                    sort === "customer" && order === "asc" ? "ascending-icon active" : "ascending-icon"
                                  }
                                  onClick={() => setOrder("asc")}
                                ></div>
                                <div
                                  class={
                                    sort === "customer" && order === "desc"
                                      ? "descending-icon active"
                                      : "descending-icon"
                                  }
                                  onClick={() => setOrder("desc")}
                                ></div>
                              </a>
                            </th>
                            <th style={{ width: "3%" }} class="text-end">
                              <a href="javascript:void(0)" class="sort-by"></a>
                            </th>
                            <th style={{ width: "200px", minWidth: "195px" }}>
                              <a href="javascript:void(0)" class="sort-by" onClick={() => setSort("feedback")}>
                                Feedback
                                <div
                                  class={
                                    sort === "feedback" && order === "asc" ? "ascending-icon active" : "ascending-icon"
                                  }
                                  onClick={() => setOrder("asc")}
                                ></div>
                                <div
                                  class={
                                    sort === "feedback" && order === "desc"
                                      ? "descending-icon active"
                                      : "descending-icon"
                                  }
                                  onClick={() => setOrder("desc")}
                                ></div>
                              </a>
                            </th>
                            <th style={{ width: "200px", minWidth: "195px" }}>
                              <a href="javascript:void(0)" class="sort-by" onClick={() => setSort("company")}>
                                Company
                                <div
                                  class={
                                    sort === "company" && order === "asc" ? "ascending-icon active" : "ascending-icon"
                                  }
                                  onClick={() => setOrder("asc")}
                                ></div>
                                <div
                                  class={
                                    sort === "company" && order === "desc"
                                      ? "descending-icon active"
                                      : "descending-icon"
                                  }
                                  onClick={() => setOrder("desc")}
                                ></div>
                              </a>
                            </th>
                            <th style={{ width: "200px", minWidth: "195px" }}>
                              <a href="javascript:void(0)" class="sort-by" onClick={() => setSort("email")}>
                                Email
                                <div
                                  class={
                                    sort === "email" && order === "asc" ? "ascending-icon active" : "ascending-icon"
                                  }
                                  onClick={() => setOrder("asc")}
                                ></div>
                                <div
                                  class={
                                    sort === "email" && order === "desc" ? "descending-icon active" : "descending-icon"
                                  }
                                  onClick={() => setOrder("desc")}
                                ></div>
                              </a>
                            </th>
                            <th style={{ width: "140px", minWidth: "130px" }}>
                              <a href="javascript:void(0)" class="sort-by" onClick={() => setSort("phone")}>
                                Phone
                                <div
                                  class={
                                    sort === "phone" && order === "asc" ? "ascending-icon active" : "ascending-icon"
                                  }
                                  onClick={() => setOrder("asc")}
                                ></div>
                                <div
                                  class={
                                    sort === "phone" && order === "desc" ? "descending-icon active" : "descending-icon"
                                  }
                                  onClick={() => setOrder("desc")}
                                ></div>
                              </a>
                            </th>
                            <th style={{ width: "200px", minWidth: "195px" }}>
                              <a href="javascript:void(0)" class="sort-by" onClick={() => setSort("location")}>
                                Location
                                <div
                                  class={
                                    sort === "location" && order === "asc" ? "ascending-icon active" : "ascending-icon"
                                  }
                                  onClick={() => setOrder("asc")}
                                ></div>
                                <div
                                  class={
                                    sort === "location" && order === "desc"
                                      ? "descending-icon active"
                                      : "descending-icon"
                                  }
                                  onClick={() => setOrder("desc")}
                                ></div>
                              </a>
                            </th>
                            <th style={{ width: "8%" }}>
                              <a href="javascript:void(0)" class="sort-by" onClick={() => setSort("profit")}>
                                Profit
                                <div
                                  class={
                                    sort === "profit" && order === "asc" ? "ascending-icon active" : "ascending-icon"
                                  }
                                  onClick={() => setOrder("asc")}
                                ></div>
                                <div
                                  class={
                                    sort === "profit" && order === "desc" ? "descending-icon active" : "descending-icon"
                                  }
                                  onClick={() => setOrder("desc")}
                                ></div>
                              </a>
                            </th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {listingData?.list?.map((val) => (
                            <tr>
                              <td>
                                <div
                                  class={`fw-medium green-text ${val?.customer?.customer_name ? "cursor-pointer" : ""}`}
                                  onClick={() => {
                                    if (val?.customer?.customer_name) {
                                      if (process.env.REACT_APP_MODE == "development") {
                                        window.open(
                                          `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val?.customer?.user_id}`,
                                        );
                                      } else {
                                        window.open(
                                          `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val?.customer?.user_id}`,
                                        );
                                      }
                                    }
                                  }}
                                >
                                  <img src={extLink} alt="External Link" />
                                  {val?.customer?.customer_name ? val?.customer?.customer_name : "N/A"}
                                  <div className="text-danger">
                                    {val?.phoned && new Date(val?.phoned) !== "Invalid Date" && (
                                      <div className="position-relative d-inline-block">
                                        <img
                                          className="align-middle"
                                          src={msgSqr}
                                          alt=""
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          title="Chat Initiated"
                                        />
                                      </div>
                                    )}
                                    {val?.customer?.credit > 0 &&
                                      `($${new Intl.NumberFormat("en-US", {
                                        style: "decimal",
                                        minimumFractionDigits: 2,
                                      }).format(val?.customer?.credit)})`}
                                  </div>
                                </div>
                              </td>
                              <td>{val?.customer?.buyer_motivation}</td>
                              <td>
                                <div className="fw-medium">
                                  Feedback:{" "}
                                  <span className="green-text">
                                    {val?.customer?.buyerFeedback?.total === 0
                                      ? "0% "
                                      : `${Math.round(
                                          (val?.customer?.buyerFeedback?.good / val?.customer?.buyerFeedback?.total) *
                                            100,
                                        )}% `}
                                  </span>
                                  <span>
                                    {val?.customer?.buyerFeedback?.total === 0
                                      ? "0% "
                                      : `${Math.round(
                                          (val?.customer?.buyerFeedback?.neutral /
                                            val?.customer?.buyerFeedback?.total) *
                                            100,
                                        )}% `}
                                  </span>
                                  <span className="text-danger">
                                    {val?.customer?.buyerFeedback?.total === 0
                                      ? "0% "
                                      : `${Math.round(
                                          (val?.customer?.buyerFeedback?.bad / val?.customer?.buyerFeedback?.total) *
                                            100,
                                        )}% `}
                                  </span>
                                </div>
                              </td>
                              <td>{val?.customer?.customer_company ? val?.customer?.customer_company : "N/A"}</td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <a className="green-text" href={`mailto:${val?.customer?.customer_email}`}>
                                    {val?.customer?.customer_email ? val?.customer?.customer_email : "N/A"}
                                  </a>
                                  {val?.customer?.customer_email && (
                                    <div className="d-text-item fw-medium" style={{ width: "24px" }}>
                                      {val?.customer?.emailstatuscode === 0 ? (
                                        <img src={greenEmail} alt="" title="Email OK" className="img-fluid" />
                                      ) : val?.customer?.emailstatuscode === 1 ? (
                                        <img
                                          src={purpleEmail}
                                          alt=""
                                          title="Email Temporarily Suspended/Unreachable or Undetermined (Soft Bounce)"
                                          className="img-fluid"
                                        />
                                      ) : val?.customer?.emailstatuscode === 2 ? (
                                        <img src={invalid} alt="" title="Email Invalid" className="img-fluid" />
                                      ) : val?.customer?.emailstatuscode === 3 ? (
                                        <img
                                          src={spamEmail}
                                          alt=""
                                          title="Email Blocked by Spam or Firewall"
                                          className="img-fluid"
                                        />
                                      ) : (
                                        <img
                                          src={notVerified}
                                          alt=""
                                          title="Email Not Verified"
                                          className="img-fluid"
                                        />
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div class="td-button-group">
                                  <img
                                    src={`https://us-central1-salvex-mail.cloudfunctions.net/requestRecipientStats?email=${val?.customer?.customer_email}`}
                                    alt=""
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="fw-medium">
                                  {val?.customer?.customer_phone ? val?.customer?.customer_phone : "N/A"}
                                </div>
                              </td>
                              <td class="text-start">
                                {val?.customer?.customer_state === "International" ||
                                val?.customer?.customer_state === null ? (
                                  <>
                                    <div class="fw-medium">
                                      {val?.customer?.customer_city && val?.customer?.customer_country
                                        ? `${val?.customer?.customer_city}, `
                                        : val?.customer?.customer_city
                                        ? val?.customer?.customer_city
                                        : ""}
                                      {val?.customer?.customer_country ? val?.customer?.customer_country : ""}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div class="fw-medium">
                                      {val?.customer?.customer_city && val?.customer?.customer_state
                                        ? `${val?.customer?.customer_city}, `
                                        : val?.customer?.customer_city
                                        ? val?.customer?.customer_city
                                        : ""}
                                      {val?.customer?.customer_state ? val?.customer?.customer_state : ""}
                                    </div>
                                  </>
                                )}
                              </td>
                              <td class="text-start">
                                <div class="fw-medium">
                                  {val?.customer?.profitBuying
                                    ? "$" +
                                      new Intl.NumberFormat("en-US", {
                                        style: "decimal",
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }).format(val?.customer?.profitBuying)
                                    : "$0.00"}
                                </div>
                                {/* <div class="fw-medium">$1,400,000.00</div> */}
                              </td>
                              <td>
                                <div class="flex-table-row">
                                  <div class="flex-table-item d-flex flex-nowrap align-items-center justify-content-center">
                                    <button
                                      className="btn btn-primary btn-xs-size btn-auto"
                                      onClick={() => {
                                        fetchUserDetail(val.user_id);
                                      }}
                                    >
                                      MORE
                                    </button>
                                  </div>
                                  <div class="flex-table-item d-flex flex-nowrap align-items-center justify-content-center">
                                    {props.auctionId > 0 && val?.phoned ? (
                                      <div className="fw-medium">
                                        Call at: <DateFormat date={val.phoned} />
                                      </div>
                                    ) : (
                                      <button
                                        className="btn btn-primary btn-xs-size btn-auto"
                                        onClick={() => handleCallEmailClicker(val.user_id)}
                                      >
                                        Call
                                      </button>
                                    )}
                                  </div>
                                  <div class="flex-table-item d-flex flex-nowrap align-items-center justify-content-center">
                                    {val?.buyer_intrest ? (
                                      <div className="fw-medium">
                                        Buyer Interest added at:
                                        <DateFormat date={val?.buyer_intrest} />
                                      </div>
                                    ) : (
                                      <button
                                        className="btn btn-primary btn-xs-size btn-auto"
                                        onClick={() => handleBuyingInterest(val?.user_id)}
                                      >
                                        Buyer Interest
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex justify-content-center align-items-center">
                                  <button
                                    className="btn btn-sm btn-primary btn-push-email"
                                    onClick={() => {
                                      window.open(
                                        process.env.REACT_APP_MODE === "development"
                                          ? `https://admin-dev.salvex.com/admin/_listings/views/key_buyer.cfm?tr=local&aucID=${props?.auctionId}&user_id=${val?.user_id}`
                                          : `https://admin.salvex.com/admin/_listings/views/key_buyer.cfm?tr=local&aucID=${props?.auctionId}&user_id=${val?.user_id}`,
                                      );
                                    }}
                                  >
                                    Push
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                  {listingData?.list?.length > 0 && (
                    <div class="table-pagination mt-5">
                      <ul class="pagination  justify-content-end">
                        <li class="page-item">
                          <a
                            class={`page-link   ${page === 1 ? "disabled" : "cursor-pointer"}`}
                            aria-label="Previous"
                            onClick={() => {
                              if (page !== 1) {
                                setPage(page - 1);
                              }
                            }}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                        <li class="page-item active">
                          <a class="page-link" href="#">
                            {page}
                          </a>
                        </li>
                        <li class="page-item">
                          {page < (Count % 10 === 0 ? Math.floor(Count / 10) : Math.floor(Count / 10) + 1) && (
                            <a
                              class={`page-link ${
                                page >= (Count % 10 === 0 ? Math.floor(Count / 10) : Math.floor(Count / 10) + 1)
                                  ? "disabled"
                                  : "cursor-pointer"
                              }`}
                              onClick={() => setPage(page + 1)}
                            >
                              {page + 1}
                            </a>
                          )}
                        </li>
                        <li class="page-item">
                          {page < (Count % 10 === 0 ? Math.floor(Count / 10) : Math.floor(Count / 10) + 1) && (
                            <a
                              class={`page-link ${
                                page >= (Count % 10 === 0 ? Math.floor(Count / 10) : Math.floor(Count / 10) + 1)
                                  ? "disabled"
                                  : "cursor-pointer"
                              }`}
                              aria-label="Next"
                              onClick={() => setPage(page + 1)}
                            >
                              <svg
                                width="8"
                                height="14"
                                viewBox="0 0 8 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                            </a>
                          )}
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
      <MoreModal
        show={showMoreModal}
        onHide={toggleMoreModal}
        userDetailData={userDetailData}
        recipientList={recipientList}
        refStart={props.refStart}
        refStop={props.refStop}
        addMessage={props.addMessage}
        deleteMessage={props.deleteMessage}
        getUserDetail={props.getUserDetail}
        changeUnsubscribe={props.changeUnsubscribe}
        auctionId={props.auctionId}
        toggleContactInformationModal={toggleContactInformationModal}
      />

      <UserContactInformationModal
        show={showContactModal}
        onHide={toggleContactInformationModal}
        userDetailData={userDetailData}
        contactInformation={contactInformation}
        setContactInformation={setContactInformation}
        stateOptions={stateOptions}
        countryOptions={countryOptions}
        loading={quickEditLoading}
        quickEditHandle={quickEditHandle}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getPushEmailClickers: (params, page, callback) => dispatch(getPushEmailClickers(params, page, callback)),
  getOtherBuyerListing: (id, params, callback) => dispatch(getOtherBuyerListing(id, params, callback)),
  getCountry: (callback) => dispatch(getCountry(callback)),
  getState: (callback) => dispatch(getState(callback)),
  getUserDetail: (userId, callback) => dispatch(getUserDetail(userId, callback)),
  buyerInterest: (params, userId, callback) => dispatch(buyerInterest(params, userId, callback)),
  callEmailClicker: (params, userId, callback) => dispatch(callEmailClicker(params, userId, callback)),
  getRecipient: (callback) => dispatch(getRecipient(callback)),
  addMessage: (params, data, callback) => dispatch(addMessage(params, data, callback)),
  deleteMessage: (params, callback) => dispatch(deleteMessage(params, callback)),
  quickEdit: (params, data, callback) => dispatch(quickEdit(params, data, callback)),
  changeUnsubscribe: (params, data, callback) => dispatch(changeUnsubscribe(params, data, callback)),
});
const mapStateToProps = (state) => ({
  emailClickersDetail: emailClickersDetail(state),
  getListings: getListings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(EndUsersOfCategory));
